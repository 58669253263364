<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                   <div class="">
                        <span class="font-semibold text-base">Profil Saya</span>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-4">
                       <div class="col-span-4 md:col-span-4">
                           <div class="flex justify-center">
                               <div class="md:w-40 md:h-40 rounded-full">
                                   <img :src="data.foto ? `${default_url}${data.foto}` : require('@/assets/default.png')" class="w-40 h-40 rounded-full object-cover" @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div>
                       <div class="col-span-8">
                            <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">

                            <ValidationObserver v-slot="{ invalid}" ref="form" >
                            <form @submit.prevent="updateProfile()" class="w-full" >
                            
                            <ValidationProvider rules="required" v-slot="{ errors }" name="Password Lama" vid="old_password" class="grid grid-cols-12 gap-4 items-center">
                            <label for="" class="block mb-2 col-span-2">Nama</label>  
                            <div class="col-span-10">
                            <input
                                v-model="data.name"
                                type="text"
                                :class="`block border border-gray-200 w-72 p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="old_password"
                                ref="old_password"
                                id="old_password"
                                placeholder="Nama" />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            

                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" name="Email" vid="email" class="grid grid-cols-12 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Email</label>  
                            <input
                                disabled
                                v-model="data.email"
                                type="text"
                                :class="`block border border-gray-200 w-72 p-2 px-4 rounded col-span-10 text-xs border-blue-300 bg-blue-50 placeholder-blue-200 text-blue-900 focus:border-blue-300`"
                                name="email"
                                ref="email"
                                id="email"
                                placeholder="Email" />
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" name="Username" vid="username" class="grid grid-cols-12 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">Username</label>  
                            <input
                                disabled
                                v-model="data.username"
                                type="text"
                                :class="`block border border-gray-200 w-72 p-2 px-4 rounded col-span-10 text-xs border-blue-300 bg-blue-50 placeholder-blue-200 text-blue-900 focus:border-blue-300`"
                                name="username"
                                ref="username"
                                id="username"
                                placeholder="Username" />
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                            </ValidationProvider>
                           

                            <button
                                :disabled="invalid"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                        </form>
                        </ValidationObserver>
                       </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
export default {
    data: () => ({
        data: {
            name:'',
            username:'',
            email:'',
            foto:''
        },
        foto:'',
        default_url:process.env.VUE_APP_IMAGES
    }),
    methods:{
        updateProfile(){
            let data = new FormData()
            data.append('name',this.data.name)
            data.append('foto',this.foto)
            let headers = { headers:{
            'Authorization': 'Bearer ' + this.token,
            }}
            this.axios.post('v1/auth/edit-profile',data,headers)
            .then((ress) => {
                this.axios.get('v1/auth/me',headers)
                .then((ress) => {
                    this.setUser(ress.data)
                })
            })
        },
         eventChange(event){
             this.default_url = ''
           const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load',()=>{
                this.data.foto=fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
       },
       ...mapActions({
           setUser:'auth/setUser'
       })
    },
    computed:{
        ...mapGetters({
            user:'auth/user',
            token:'auth/token',
        }),
       
    },
    created() {
        let user = this.user
        this.data.name = user.name
        this.data.username = user.username
        this.data.email = user.email
        this.data.foto = user.foto
   
    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>